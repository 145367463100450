import React from "react";
import { WMKLink } from "wmk-lib";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import get from "lodash/get";
import { colors } from "../../../../vars/palette";

const Wrap = styled.div`
  display: flex;
  * {
    margin-right: 15px;
    font-size: 0.75rem;
    font-weight: 300;
  }
  button {
    border: none;
    background: none;
    height: 1.1rem;
    padding: 0;
    color: ${colors.hex("primary")};
    :hover {
      text-decoration: underline;
    }
  }
  p.curr-cat {
    color: green;
    text-decoration: underline;
  }
  img {
    width: 10px;
    height: 15px;
    position: relative;
    top: 2px;
  }
`;

const query = graphql`
  {
    file(name: { eq: "leaf" }) {
      childImageSharp {
        fixed(width: 10, height: 15, fit: FILL) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

export const Breadcrumbs = ({ currCat, currMedia }) => {
  const { file } = useStaticQuery(query);
  const fixed = get(file, "childImageSharp.fixed");
  const Leaf = () => (fixed ? <Img fixed={fixed} alt="" /> : null);
  //console.log(currCat,currMedia);
  return (
    <Wrap>
      <WMKLink to="https://ntmwd.com" target="self">
        Home
      </WMKLink>
      <Leaf />
      <WMKLink to={`/`}>News Room</WMKLink>
      <Leaf />
      <WMKLink to={"/photos-videos/"}>Videos & Photos</WMKLink>
      {currCat && (
        <>
          <Leaf />
          <WMKLink to={`/photos-videos/${currCat.slug}`} className="curr-cat">
            {currCat.title}
          </WMKLink>
        </>
      )}
      {currMedia && (
        <>
          <Leaf />
          <p className="curr-cat">{currMedia.title}</p>
        </>
      )}
    </Wrap>
  );
};
