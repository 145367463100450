import { graphql } from "gatsby";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Breadcrumbs } from "../components/common/MediaGallery";
import { MGTitle } from "../components/common/MediaGallery/parts/MGTitle";
import { getMediaTag } from "../components/common/MediaGallery/util/tagging";
import Layout from "../components/layout/MainLayout";
import YouTube from "react-youtube";
import Vimeo from "@u-wave/react-vimeo";
import get from "lodash/get";
import { SEO } from "wmk-lib";
import { siteTitle, subdomainUrl, twitter } from "../vars/helpers";

export default ({ data }) => {
  const { vid, pic, vim } = data;
  const activeMedia = vid
    ? {
        ...vid,
        type: "video",
        tags: getMediaTag({ node: vid }),
        //date: moment(vid.date),
      }
    : vim
    ? {
        ...vim,
        type: "video",
        tags: getMediaTag({ node: vim }),
        //date: moment(vid.date),
      }
    : {
        ...pic,
        type: "photo",
        tags: getMediaTag({ node: pic }),
        //date: moment(pic.date),
      };
  const { title } = activeMedia;
  const id = get(activeMedia, "photo_id", get(activeMedia, "videoId"));
  return (
    <Layout>
      <SEO
        baseUrl={subdomainUrl}
        title={title}
        siteTitle={siteTitle + " Photos & Videos"}
        slug={vid || vim ? "vids/" + id : "pics/" + id}
        twitterHandle={twitter}
        twitterImage={null}
        ogImage={null}
        path={"/photos-videos/"}
      />
      <Container>
        <Breadcrumbs currMedia={activeMedia} currCat={activeMedia.tag} />
        {vid ? (
          <Video vid={activeMedia} />
        ) : vim ? (
          <VimeoVid vim={activeMedia} />
        ) : pic ? (
          <Photo pic={activeMedia} />
        ) : null}
      </Container>
    </Layout>
  );
};

const VimeoVid = ({ vim }) => {
  const { description, title, videoId } = vim;
  return (
    <Row className="flex-column">
      <Col>
        <MGTitle>{title}</MGTitle>
        <div>{description}</div>
      </Col>
      <Col>
        <Vimeo video={videoId} />
      </Col>
    </Row>
  );
};

const Video = ({ vid }) => {
  const { description, title, videoId } = vid;
  return (
    <Row className="flex-column">
      <Col>
        <MGTitle>{title}</MGTitle>
        <div>{description}</div>
      </Col>
      <Col>
        <YouTube videoId={videoId} />
      </Col>
    </Row>
  );
};

const Photo = ({ pic }) => {
  const { url_l, url_m, title, description } = pic;
  return (
    <Row className="flex-column">
      <Col>
        <MGTitle>{title}</MGTitle>
        <div>{description}</div>
      </Col>
      <Col>
        <Pic
          src={url_l ? url_l : url_m}
          alt={title}
          lowRes={url_l ? undefined : "true"}
        />
      </Col>
    </Row>
  );
};

const Pic = styled.img`
  width: ${({ lowRes }) => (lowRes ? "auto%" : "100%")};
  max-width: ${({ lowRes }) => (lowRes ? "100%" : "none")};
`;

export const query = graphql`
  query mediaQuery($uid: String!) {
    pic: flickrPhoto(photo_id: { eq: $uid }) {
      ...NodeFlickrFields
    }
    vid: youtubeVideo(videoId: { eq: $uid }) {
      ...NodeYoutubeFields
    }
    vim: vimeoVideo(id: { eq: $uid }) {
      ...NodeVimeoFields
    }
  }
`;
