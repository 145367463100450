import React from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import { WMKLink } from "wmk-lib";

const StyledTitle = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
  text-transform: uppercase;
  font-size: 0.8rem;
  color: white;
  position: relative;
  border: none;
  display: inline-block;
  padding: 0.5rem;
  text-align: left;
`;
const WrapCol = styled(Col)`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .wmk-link {
    position: relative;
  }
`;

const StyledOverlay = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, .6) 0%,
    rgba(0, 0, 0, 0) 40%
  );
`;

export const MGCategoryTile = ({ tag, bg, width, height, title }) => {
  return (
    <WrapCol lg={3} md={6}>
      <WMKLink
        to={tag.slug}
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          // backgroundPosition: 'center',
          width: width,
          height: height,
        }}
      >
        <StyledTitle>{title}</StyledTitle>
        <StyledOverlay />
      </WMKLink>
      {/* <button onClick={() => setCurrCat(tag)} className="content-wrap"></button> */}
    </WrapCol>
  );
};
