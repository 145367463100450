import React from "react";
import { MGCategoryTile, Breadcrumbs } from "../index";
import { Container, Row, Col } from "react-bootstrap";
import get from "lodash/get";
import { manifest } from "../util/tagging";
import { MGTitle } from "./MGTitle";
import { HoverTile } from "./HoverTitle";

const TILE_WIDTH = 350;
const TILE_HEIGHT = 175;

export const MediaGallery = ({ media, init }) => {
  //const [init, setinit] = useState(init);
  //const [currMedia, setCurrMedia] = useState();
  const key = get(init, "slug");

  // create an object where the key matches the slug of the media items category
  const categories = {};
  media.sort((a, b) => {
    return a.date.isBefore(b.date) ? 1 : -1;
  });
  media.forEach((m) => {
    //the slug will be the key
    const tags = get(m, "tags");
    //bool to represent whether slug already exists as a key

    tags.forEach((t) => {
      const slug = get(t, "slug");
      const check = categories[slug];
      if (!check) {
        //if the key doesn't exist, create an empty array at the given key/slug
        categories[slug] = [];
        // push the media item into it
        categories[slug].push(m);
      } else {
        // since the key already exists, we just need to push here
        categories[slug].push(m);
      }
    });
  });

  // now we need an array to map JSX for rendering category tiles
  const cats = Object.keys(categories).map((c) => {
    const key = c.replace(/-/g, "");
    const media = categories[c];
    const { type } = media[0];
    const bg =
      type === "photo"
        ? get(media[0], "url_m")
        : get(media[0], "thumbnail.medium")
        ? get(media[0], "thumbnail.medium")
        : get(media[0], "thumb.childImageSharp.fixed.src");
    //if (type === "video") console.log(type, bg, media[0]);
    //console.log(get(media[0], "thumb.childImageSharp.fixed.src"));
    return {
      media,
      slug: c,
      order: get(manifest, key + ".order"),
      title: get(manifest, key + ".title"),
      bg,
      key,
    };
  });
  // sort categories by order
  cats.sort((a, b) => {
    return a.order - b.order;
  });

  //console.log(cats);

  return (
    <Container>
      <Row>
        <Col>
          <Breadcrumbs
            currCat={init} //init={init} setinit={setinit}
          />
        </Col>
      </Row>
      <Row>
        <Col>{init && <MGTitle>{init.title}</MGTitle>}</Col>
      </Row>
      <Row>
        {!init
          ? cats.map((category) => {
              const { media, slug, title, bg, key } = category;
              return slug !== "general" ? (
                <MGCategoryTile
                  media={media}
                  key={slug}
                  tag={category}
                  title={title}
                  bg={bg}
                  width={TILE_WIDTH}
                  height={TILE_HEIGHT}
                  objKey={key}
                />
              ) : null;
            })
          : init &&
            categories[key] &&
            categories[key].map((media, i) => {
              const { type, title, uid } = media;
              return (
                <HoverTile
                  key={"media" + i}
                  media={media}
                  type={type}
                  title={title}
                  to={uid}
                  width={TILE_WIDTH}
                  height={TILE_HEIGHT}
                />
              );
            })}
      </Row>
    </Container>
  );
};
