import React from "react";
import styled from "styled-components";

const GalleryTitle = styled.h4`
  color: #4075d2;
  text-transform: uppercase;
  font-weight: bold;
  padding: 1rem 0 .5rem 0;
`;

export const MGTitle = ({ children }) => (
  <GalleryTitle>{children}</GalleryTitle>
);
